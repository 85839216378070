var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table"},[(!_vm.server)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"ru-RU","checkbox-color":"#FEE600","loading":_vm.loading,"page":_vm.page,"custom-sort":_vm.customSort,"headers":_vm.headers,"show-select":!_vm.hideselect,"items":_vm.data,"itemsPerPage":_vm.perPage,"hide-default-footer":"","no-data-text":"Нет данных","item-key":"id","loading-text":"Загружаем данные таблицы"},on:{"input":function($event){return _vm.enterSelect()},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":"","color":"green"}})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status=='wait')?_c('div',{staticClass:"status_wait"},[_vm._v("В процессе")]):_vm._e(),(item.status=='1')?_c('div',{staticClass:"status_end"},[_vm._v("Завершен")]):_vm._e(),(item.status=='work')?_c('div',{staticClass:"status_success"},[_vm._v("Включен")]):_vm._e(),(item.status=='stop')?_c('div',{staticClass:"status_stop"},[_vm._v("Остановлен")]):_vm._e(),(item.status=='success')?_c('div',{staticClass:"status_success"},[_vm._v("Одобрено")]):_vm._e(),(item.status=='waiting')?_c('div',{staticClass:"status_wait"},[_vm._v("Ждет проверки")]):_vm._e(),(item.status=='cancelled')?_c('div',{staticClass:"status_stop"},[_vm._v("Отказано")]):_vm._e()]}},{key:"item.sender_status",fn:function(ref){
var item = ref.item;
return [(item.sender_status=='process')?_c('div',{staticClass:"status_success"},[_vm._v("Рассылается")]):_vm._e(),(item.sender_status=='pause')?_c('div',{staticClass:"status_wait"},[_vm._v("Пауза")]):_vm._e(),(item.sender_status=='stop')?_c('div',{staticClass:"status_stop"},[_vm._v("Остановлено")]):_vm._e()]}},{key:"item.sender_type",fn:function(ref){
var item = ref.item;
return [(item.sender_type=='manual')?_c('div',{staticClass:"status_stop"},[_vm._v("Ручная")]):_vm._e(),(item.sender_type=='api')?_c('div',{staticClass:"status_success"},[_vm._v("API")]):_vm._e()]}},{key:"item.user_status",fn:function(ref){
var item = ref.item;
return [(item.user_status=='waiting')?_c('div',{staticClass:"status_wait"},[_vm._v("Ждет проверки")]):_vm._e(),(item.user_status=='active')?_c('div',{staticClass:"status_success"},[_vm._v("Активен")]):_vm._e(),(item.user_status=='unactive')?_c('div',{staticClass:"status_stop"},[_vm._v("Не активен")]):_vm._e()]}},{key:"item.operator",fn:function(ref){
var item = ref.item;
return [(item.operator=='МТС')?_c('div',[_vm._v("qqq "),_c('Icon',{attrs:{"name":"op_mts"}})],1):_vm._e()]}},{key:"item.status_pay",fn:function(ref){
var item = ref.item;
return [(item.status_pay=='waiting')?_c('div',{staticClass:"status_wait"},[_vm._v("Ждет проверки")]):_vm._e(),(item.status_pay=='success')?_c('div',{staticClass:"status_success"},[_vm._v("Успешно")]):_vm._e(),(item.status_pay=='cancelled')?_c('div',{staticClass:"status_stop"},[_vm._v("Отказано")]):_vm._e()]}},{key:"item.Pcosent_Convesion_Target",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.Pcosent_Convesion_Target)+" %")])]}},{key:"item.target_limit",fn:function(ref){
var item = ref.item;
return [(item.target_on)?_c('div',{staticClass:"target_container"},[_c('div',{staticClass:"target_des"},[_vm._v(_vm._s(item.limit)+"/"+_vm._s(item.target))]),(item.target_rate>0)?_c('div',{staticClass:"target",on:{"click":function($event){return _vm.$emit("clearTarget",item)}}},[_c('Icon',{attrs:{"name":"delete"}})],1):_vm._e()]):_c('div',[_vm._v("---")])]}},{key:"item.limit",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s('6< ' + item))])]}},{key:"item.target_price",fn:function(ref){
var item = ref.item;
return [(item.target_on && item.target>0)?_c('div',{staticClass:"target_container"},[_c('div',{staticClass:"target_des"},[_vm._v(_vm._s((item.target_rate / item.target).toFixed(2))+" р.")])]):_c('div',[_vm._v("---")])]}},{key:"item.nums",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.contacts)+"/"+_vm._s(item.call))])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" https://gate.crmcalls.ru/api/web/u"+_vm._s(_vm.$store.getters['user/getName'].replace(/[^0-9]/g, ""))+"/"+_vm._s(item.guid)+" ")])]}},{key:"item.unique",fn:function(ref){
var item = ref.item;
return [(item.unique==1)?_c('div',[_vm._v("Да")]):_c('div',[_vm._v("Нет")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_text"},[_vm._v(_vm._s(item.date))])]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_text",class:_vm.numClass(item.sum)},[_vm._v(_vm._s(item.sum))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_text"},[_vm._v(_vm._s(item.phone))])]}},{key:"item.callname",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.currentType=='auto')?_c('div',{staticClass:"auto"},[_vm._v("авто")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.items.length > 1)?_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"props"},'div',attrs,false),on),[_c('Icon',{attrs:{"name":"props"}})],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(menu_item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.$emit(menu_item.action,item)}}},[(item.status=='stop' || menu_item.action!='play')?_c('v-list-item',[_c('v-list-item-title',{class:_vm.itemClass(menu_item.title)},[_vm._v(" "+_vm._s(_vm.itemText(menu_item, item))+" ")])],1):_vm._e()],1)}),0)],1):_c('div',{class:_vm.itemClass(_vm.items[0].title),on:{"click":function($event){return _vm.$emit(_vm.items[0].action,item)}}},[_vm._v(" "+_vm._s(_vm.items[0].title)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{staticClass:"my-4",attrs:{"color":"#E9EAEA","total-visible":7,"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),(_vm.server)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"server-items-length":Number(_vm.pageCount_server),"page":_vm.server_page,"options":_vm.options,"checkbox-color":"#FEE600","show-select":!_vm.hideselect,"locale":"ru-RU","no-data-text":"Нет данных","headers":_vm.headers,"itemsPerPage":_vm.perPage,"items":_vm.data,"hide-default-footer":"","item-key":"id","loading-text":"Загружаем данные таблицы"},on:{"input":function($event){return _vm.enterSelect()},"update:page":function($event){_vm.server_page=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":"","color":"green"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.items.length > 1 && item.status!='off')?_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"props"},'div',attrs,false),on),[_c('Icon',{attrs:{"name":"props"}})],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(menu_item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.$emit(menu_item.action, item)}}},[_c('v-list-item',[_c('v-list-item-title',{class:_vm.itemClass(menu_item.title)},[_vm._v(" "+_vm._s(_vm.itemText(menu_item, item))+" ")])],1)],1)}),0)],1):_c('div',{class:_vm.itemClass(_vm.items[0].title),on:{"click":function($event){return _vm.$emit(_vm.items[0].action,item)}}},[_vm._v(" "+_vm._s(_vm.items[0].title)+" ")])]}},{key:"item.n",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},on:{"click":function($event){return _vm.copy(item.id)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"table_text"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.n)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.id))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status=='wait')?_c('div',{staticClass:"status_wait"},[_vm._v("Одобрено")]):_vm._e(),(item.status=='1')?_c('div',{staticClass:"status_end"},[_vm._v("Отклонено")]):_vm._e(),(item.status=='2')?_c('div',{staticClass:"status_uncall"},[_vm._v("недозвон")]):_vm._e(),(item.status=='success')?_c('div',{staticClass:"status_success"},[_vm._v("Успешно")]):_vm._e(),(item.status=='4')?_c('div',{staticClass:"status_stop"},[_vm._v("Не доставлено")]):_vm._e(),(item.status=='10')?_c('div',{staticClass:"status_success"},[_vm._v("Доставлено")]):_vm._e(),(item.status=='stop')?_c('div',{staticClass:"status_stop"},[_vm._v("Остановлено")]):_vm._e(),(item.status=='active')?_c('div',{staticClass:"status_success"},[_vm._v("Рассылается")]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type=='manual')?_c('div',{staticClass:"status_wait"},[_vm._v("Ручная")]):_vm._e(),(item.type=='api')?_c('div',{staticClass:"status_success"},[_vm._v("API")]):_vm._e()]}},{key:"item.statusAlpha",fn:function(ref){
var item = ref.item;
return [(item.status=='wait')?_c('div',{staticClass:"status_wait"},[_vm._v("На рассмотрении")]):_vm._e(),(item.status=='cancel')?_c('div',{staticClass:"status_cancel"},[_vm._v("Отклонено")]):_vm._e(),(item.status=='success')?_c('div',{staticClass:"status_success"},[_vm._v("Успешно")]):_vm._e(),(item.status=='off')?_c('div',{staticClass:"status_cancel"},[_vm._v("Отключено")]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(new Date(item.created)))])]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(new Date(item.updated)))])]}},{key:"item.operator",fn:function(ref){
var item = ref.item;
return [(item.operator=='МТС')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_mts"}}),_vm._v(" МТС ")],1):(item.operator=='Билайн')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_beeline"}}),_vm._v(" Билайн ")],1):(item.operator=='Мегафон')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_megafon"}}),_vm._v(" Мегафон ")],1):(item.operator=='Tele2')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_tele"}}),_vm._v(" Tele2 ")],1):(item.operator=='Тинькофф Мобайл')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_tinkoff"}}),_vm._v(" Тинькофф Мобайл ")],1):(item.operator=='Yota')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_yota"}}),_vm._v(" Yota ")],1):(item.operator=='Ростелеком')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_rst"}}),_vm._v(" Ростелеком ")],1):_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_other"}}),_vm._v(" "+_vm._s(item.operator ? item.operator : "Не определено")+" ")],1)]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_text",class:{'status_uncall':_vm.textFormat(item.text)=='Нет данных'}},[_vm._v(" "+_vm._s(_vm.textFormat(item.text))+" ")])]}},{key:"item.phoneB",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_text"},[_vm._v(" "+_vm._s('+' + item.phoneB.substr(0, 1) + ' (' + item.phoneB.substr(1, 3) + ') ' + item.phoneB.substr(4, 3) + '-' + item.phoneB.substr(7, 2) + '-' + item.phoneB.substr(9, 2))+" ")])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_text"},[_vm._v(" "+_vm._s('+' + item.phone.substr(0, 1) + ' (' + item.phone.substr(1, 3) + ') ' + item.phone.substr(4, 3) + '-' + item.phone.substr(7, 2) + '-' + item.phone.substr(9, 2))+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-pagination',{staticClass:"my-4",attrs:{"color":"#E9EAEA","total-visible":7,"length":Math.ceil(_vm.pageCount_server/_vm.perPage)},model:{value:(_vm.server_page),callback:function ($$v) {_vm.server_page=$$v},expression:"server_page"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./DashboardView.vue?vue&type=template&id=2d8afa42&scoped=true&"
import script from "./DashboardView.vue?vue&type=script&lang=js&"
export * from "./DashboardView.vue?vue&type=script&lang=js&"
import style0 from "./DashboardView.vue?vue&type=style&index=0&id=2d8afa42&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d8afa42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
